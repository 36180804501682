@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~vanilla-cookieconsent/dist/cookieconsent.css';
/* You can add global styles to this file, and also import other style files */

@layer base {
  p {
    @apply text-xs;
  }
}

html {
  font-size: 16px;
}

a {
  color: #0078d4;
}
